<template>
    <div class="page">
        <div class="page-header pd">

            <div class="panel" style="width: 100%;">
                <div class="filters">
                    <div class="filter">
                        <label>User</label>
                        <input type="text" @focus="displayUsers = true" v-if="!user"/>
                        <input v-else type="text" :value="user.attributes.email" @click="displayUsers=true"/>
                        <select-user @close="displayUsers = false" v-if="displayUsers" @select="selectUser" @clear="clearUser"/>
                    </div>

                    <div class="filter">
                        <label>Action</label>
                        <select v-model="filters.action">
                            <option :value="null" selected></option>
                            <option value="saved">Saved</option>
                            <option value="created">Created</option>
                            <option value="deleted">Deleted</option>
                        </select>
                    </div>

                    <div class="filter">
                        <label>Type</label>
                        <select v-model="filters.type">
                            <option :value="null" selected></option>
                            <option v-for="type in ['Bad Word','Channel','Media','Player','Player Group','Playlist','Template','User','User Group']"
                                    :value="type.replace(' ','')">
                                {{ type }}
                            </option>
                        </select>
                    </div>

                    <div class="filter">
                        <label>Start</label>
                        <br/>
                        <datepicker v-model="start_date" type="date" v-if="start_date" @change="updateDates"/>
                    </div>

                    <div class="filter">
                        <label>End</label>
                        <br/>
                        <datepicker type="date" v-model="end_date" v-if="end_date" @change="updateDates"/>
                    </div>

                    <div class="filter">
                        <div><b>Options</b></div>
                        <label class="filter-option"><input type="checkbox" v-model="filters.values"/>Include the values</label>
                    </div>
                    <div style="clear: both;"></div>

                    <div class="actions">
                        <sc-button @click="resetFilters" color="secondary">
                            <awesome-icon icon="sync-alt" class="icon"/>
                            Clear filter
                        </sc-button>

                        <sc-button @click="fetch">
                            <awesome-icon icon="file-alt" class="icon"/>
                            Apply filter
                        </sc-button>

                        <sc-button @click="exportLog">
                            <awesome-icon icon="download" class="icon"/>
                            Export
                        </sc-button>
                    </div>
                </div>
            </div>
        </div>

        <sc-table :table="table" v-if="table">

            <template #headers>
                <sc-table-header :table="table" v-for="(column,name) in table.columns" :field="name"
                                 v-if="!displayValues && !name.includes('value') || displayValues"
                                 :key="name">
                    {{ name }}
                </sc-table-header>
                <div class="overflow-header" v-show="table.overflowing">more</div>

            </template>

            <template #column-user="{row}">
                <sc-table-cell :table="table" field="user">
                    {{ formattedUser(row) }}
                </sc-table-cell>
            </template>

            <template #column-old_value="{row}">
                <div v-if="!displayValues" style="display:none;"></div>

                <sc-table-cell :table="table" field="old_value" class="values" v-else>
                    <template v-if="row.attributes.old_value">
                        <span class="line"
                              v-if="Object.keys(row.attributes.old_value).length > 0"
                              v-for="key in Object.keys(row.attributes.old_value)">
                                <b>{{ key }}:</b>&nbsp; {{ row.attributes.old_value[key] }}
                        </span>
                    </template>
                    <span v-else class="not-available"><b>n/a</b></span>
                </sc-table-cell>
            </template>

            <template #column-new_value="{row}">
                <div v-if="!displayValues" style="display:none;"></div>

                <sc-table-cell :table="table" field="new_value" class="values" v-else>
                    <template v-if="row.attributes.new_value">
                        <span class="line"
                              v-if="Object.keys(row.attributes.new_value).length > 0"
                              v-for="key in Object.keys(row.attributes.new_value)">
                            <b>{{ key }}:</b>&nbsp; {{ row.attributes.new_value[key] }}
                        </span>
                    </template>
                    <span v-else class="not-available"><b>n/a</b></span>
                </sc-table-cell>
            </template>
        </sc-table>
    </div>
</template>

<script>
import moment from 'moment-timezone';
import ScSearch from "@/components/sc-search";
import datepicker from 'vue2-datepicker';
import SelectUser from "@/views/app/admin/activity-logs/select-user";
import 'vue2-datepicker/index.css';

export default {
  name: "all",
  components: {SelectUser, ScSearch, datepicker},
  data() {
    return {
      start_date: undefined,
      end_date: undefined,
      filters: {
        start_date: moment().subtract(30, 'days').format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
        values: false,
        user: null,
        action: null,
        type: null
      },
      user: undefined,
      displayValues: false,
      displayUsers: false,
      table: this.$stable.createTable({
          name: `List of Activity Logs`,
          fetchArguments: () => {
            return Object.keys(this.filters).map((k) => {
              return this.filters[k] !== null ? `&${k}=${this.filters[k].toString()}` : ''
            }).join('')
          },
          amount: 10,
          url: 'activity-logs/filtered',
          // sortBy: 'word',
          columns: {
            created_at: {
              name: 'date',
              target: 'attributes.created_at',
              type: 'date',
              sort: false,
            },
            action: {
              target: 'attributes.action',
              sort: false,
            },
            type: {
              target: 'attributes.type',

              sort: false,
            },
            user: {
              sort: false,
            },
            old_value: {
              target: 'attributes.old_value',
              name: "Old Value",
              fill: true,
              sort: false
            },
            new_value: {
              target: 'attributes.new_value',
              name: "New Value",
              fill: true,
              sort: false
            },
          }
        }
      ),
    }
  },

  computed: {},

  mounted() {
    this.resetDates();
    // this.fetchUsers();
    this.fetch()
    // })
  },

  methods: {
    fetch() {
      this.table.fetch()
        .then((res) => {
          this.displayValues = this.filters.values;
        });
    },

    resetFilters() {
      this.resetDates();
      this.clearUser()
      this.filters.values = false;
      this.filters.action = null;
      this.filters.type = null;
      this.fetch();
    },

    resetDates() {
      this.start_date = moment().subtract(30, 'days').toDate();
      this.end_date = moment().toDate();
      this.updateDates();
    },

    updateDates() {
      this.filters.start_date = moment(this.start_date).format("YYYY-MM-DD");
      this.filters.end_date = moment(this.end_date).format("YYYY-MM-DD");
    },

    search() {
      this.table.fetch();
    },

    formattedUser(row) {
      if (row.created_by) {
        let u = row.created_by.data.attributes;
        return `${u.first_name} ${u.last_name} (${u.email})`
      }
    },
    selectUser(u) {
      this.user = u;
      this.filters.user = u.id;
    },
    clearUser() {
      this.user = undefined
      this.filters.user = null;
    },

    exportLog() {
      let query = "activity-logs/filtered/export?";
      query += Object.keys(this.filters).map((k) => {
        return this.filters[k] !== null ? `${k}=${this.filters[k].toString()}&` : ''
      }).join('')

      this.$talker.api(query,{
        responseType: 'blob'
      })
      .then((res) => {
        const name = `ActivityLog-${this.getDateTimeStamp()}.xlsx`;
        this.downloadFile(this.createExcelFile(res.data), name);
      })
    },

    getDateTimeStamp() {
      // format YYYY-MM-DD_HHhmm
      return moment().format('YYYY-MM-DD_HH%mm').replace('%', 'h')
    },

    createExcelFile(data) {
      return new Blob([data], {type: 'application/vnd.ms-excel'});
    },

    downloadFile(blob, name) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

  }
}
</script>

<style scoped lang="scss">
.btns {
    text-align: center;
    padding: 2em 0 0.5em;
}

.not-available {
    color: $color-primary;
}

.filters {
    width: 100%;

    .filter {
        display: inline-block;
        text-align: center;
        width: calc(100% / 6);
        min-width: 300px;
        padding: 1em;
        box-sizing: border-box;

        label {
            font-weight: bold;
        }

        input {
            margin-top: 1em;
        }
    }

    .filter-option {
        font-weight: normal !important;

        label, input {
            display: inline-block;
            width: initial !important;
        }

        input {
            margin-right: 1em;
            min-width: initial;
        }
    }

    .actions {
        clear: left;
        display: flex;
        float: right;
        margin: 1em 0;

        div {
            display: inline-block;
            margin: .5em 1em;
        }
    }
}

</style>